export const getAnalyticsData = (
  item,
  needJson = false,
  { selectedtab, page }
) => {
  let analyticsData = {
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${item.name}`,
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  if (page === "search" && selectedtab === "technical") {
    analyticsData = {
      ...analyticsData,
      eventAction: "search results:technical:main content area:sort by",
      eventName: "search results:technical:main content area:sort by",
      internalLinkPosition: "search results",
      internalLinkType: "search results:sort by",
      internalLinkZoneName: "search results:technical:main content area",
    }
  }
  switch (page) {
    case "search":
      analyticsData = {
        ...analyticsData,
        eventAction: `search results:${selectedtab}:main content area:sort by`,
        eventName: `search results:${selectedtab}:main content area:sort by`,
        internalLinkPosition: "search results",
        internalLinkType: "search results:sort by",
        internalLinkZoneName: `search results:${selectedtab}:main content area`,
      }
      break
    case "favorites":
      analyticsData = {
        ...analyticsData,
        eventAction: `my account:favorites:${selectedtab}:sort by`,
        eventName: `my account:favorites:${selectedtab}:sort by`,
        internalLinkPosition: "favorites",
        internalLinkType: "my account:sort by",
        internalLinkZoneName: `my account:favorites:${selectedtab}`,
      }
      break

    case "article":
      analyticsData = {
        ...analyticsData,
        eventAction: "support:help and faq:article list​:sort by",
        eventName: "support:help and faq:article list​:sort by",
        internalLinkPosition: "knowledge articles",
        internalLinkType: "support:help and faq:sort by",
        internalLinkZoneName: "support:help and faq:article list​​",
      }
      break
    case "active-services":
      analyticsData = {
        ...analyticsData,
        eventAction: "my account:services:active services​:sort by",
        eventName: "my account:services:active services​:sort by",
        internalLinkPosition: "my services",
        internalLinkType: "my account:sort by",
        internalLinkZoneName: "my account:services:active services",
      }
      break
    case "past-services":
      analyticsData = {
        ...analyticsData,
        eventAction: "my account:services:past services​:sort by",
        eventName: "my account:services:past services​:sort by",
        internalLinkPosition: "my services",
        internalLinkType: "my account:sort by",
        internalLinkZoneName: "my account:services:past services",
      }
      break
    case "literature":
      analyticsData = {
        ...analyticsData,
        eventAction: "literature:sort by",
        eventName: "literature:sort by",
        internalLinkPosition: "literature",
        internalLinkType: "literature:sort by",
        internalLinkZoneName: "literature:main content area",
      }
      break
    case "press-release":
      analyticsData = {
        ...analyticsData,
        eventAction: "press room:press releases:sort by",
        eventName: "press room:press releases:sort by",
        internalLinkPosition: "press room",
        internalLinkType: "press room:sort by",
        internalLinkZoneName: "press room:press releases:main content area",
      }
      break
    case "press-kits":
      analyticsData = {
        ...analyticsData,
        eventAction: "press room:press kits:sort by",
        eventName: "press room:press kits:sort by",
        internalLinkPosition: "press room",
        internalLinkType: "press room:sort by",
        internalLinkZoneName: "press room:press kits:main content area",
      }
      break
    case "my products":
      analyticsData = {
        ...analyticsData,
        eventAction: "my account:products:sort by filter",
        eventName: "my account:products:sort by filter",
        internalLinkPosition: "my account",
        internalLinkType: "my account:sort by",
        internalLinkZoneName: "my account:products",
      }
      break
    default:
      analyticsData = {
        ...analyticsData,
        eventAction: "plp sort by filter",
        eventName: "plp sort by filter",
        internalLinkPosition: "plp",
        internalLinkType: "plp:sort by",
        internalLinkZoneName: "plp:sort by filter",
      }
  }
  if (needJson) return analyticsData
  return JSON.stringify(analyticsData)
}
