import React from "react"

import styles from "@/components/ProductList/v2/ProductListView/ProductsPerPage/index.module.scss"
import { sanitizeTextForAnalytics } from "@/utils/helper"

const ProductsPerPage = ({
  data = [],
  texts,
  page,
  onChange,
  totalResults,
  curRows = 30,
  pos = "top",
  selectedTab,
}) => {
  const getAnalyticsData = (limit = "", all = false) => {
    limit = limit + ""
    let analyticsData = ""
    if (page === "search") {
      if (!all) {
        analyticsData = JSON.stringify({
          eventAction: `search results:${selectedTab}:main content area:pagination`,
          eventName: `search results:${selectedTab}:main content area:pagination`,
          eventType: "navigation",
          eventMsg: "n/a",
          eventStatus: "n/a",
          internalLinkName: limit,
          internalLinkPosition: "search results",
          internalLinkType: `search results:pagination ${pos}`,
          internalLinkZoneName: `search results:${selectedTab}:main content area`,
          internalLinkURL: "n/a",
          clickInternalLinks: "true",
        })
      } else {
        analyticsData = JSON.stringify({
          eventAction: `search results:${selectedTab}:main content area:pagination`,
          eventName: `search results:${selectedTab}:main content area:pagination`,
          eventType: "navigation",
          eventMsg: "n/a",
          eventStatus: "n/a",
          internalLinkName: texts.all,
          internalLinkPosition: "search results",
          internalLinkType: `search results:pagination ${pos}`,
          internalLinkZoneName: `search results:${selectedTab}:main content area`,
          internalLinkURL: "n/a",
          clickInternalLinks: "true",
        })
      }
    } else if (page === "technicalspecs") {
      if (!all) {
        analyticsData =
          '{"eventAction":"technical specs:main content area​:pagination","eventName":"technical specs:main content area​:pagination","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          limit +
          '","internalLinkPosition":"tech spec","internalLinkType":"technical specs​:pagination ' +
          pos +
          '","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
      } else {
        analyticsData =
          '{"eventAction":"technical specs:main content area​:pagination","eventName":"technical specs:main content area​:pagination","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          texts.all +
          '","internalLinkPosition":"tech spec","internalLinkType":"technical specs​:pagination ' +
          pos +
          '","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
      }
    } else if (page === "favorites") {
      if (!all) {
        analyticsData = JSON.stringify({
          eventAction: `my account:favorites:${selectedTab}:pagination`,
          eventName: `my account:favorites:${selectedTab}:pagination`,
          eventType: "navigation",
          eventMsg: "n/a",
          eventStatus: "n/a",
          internalLinkName: `${limit}`,
          internalLinkPosition: "favorites",
          internalLinkType: "my account:pagination bottom",
          internalLinkZoneName: `my account:favorites:${selectedTab}`,
          internalLinkURL: "n/a",
          clickInternalLinks: "true",
        })
      } else {
        analyticsData = JSON.stringify({
          eventAction: `my account:favorites:${selectedTab}:pagination`,
          eventName: `my account:favorites:${selectedTab}:pagination`,
          eventType: "navigation",
          eventMsg: "n/a",
          eventStatus: "n/a",
          internalLinkName: `${texts.all}`,
          internalLinkPosition: "favorites",
          internalLinkType: "my account:pagination bottom",
          internalLinkZoneName: `my account:favorites:${selectedTab}`,
          internalLinkURL: "n/a",
          clickInternalLinks: "true",
        })
      }
    } else if (page === "article") {
      analyticsData = JSON.stringify({
        eventAction: "support:help and faq:article list:pagination",
        eventName: "support:help and faq:article list:pagination",
        eventType: "navigation",
        eventMsg: "n/a",
        eventStatus: "n/a",
        internalLinkName: !all ? limit : texts.all,
        internalLinkPosition: "knowledge articles",
        internalLinkType: "support:help and faq:pagination " + pos,
        internalLinkZoneName: "support:help and faq:article list",
        internalLinkURL: "n/a",
        clickInternalLinks: "true",
      })
    } else if (page === "literature") {
      if (!all) {
        analyticsData =
          '{"eventAction":"literature:pagination","eventName":"literature:pagination","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          limit +
          '","internalLinkPosition":"literature","internalLinkType":"literature:pagination","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
      } else {
        analyticsData =
          '{"eventAction":"literature:pagination","eventName":"literature:pagination","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          texts.all +
          '","internalLinkPosition":"literature","internalLinkType":"literature:pagination","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
      }
    } else if (page === "press-release") {
      if (!all) {
        analyticsData =
          '{"eventAction":"press room:press releases:pagination","eventName":"press room:press releases:pagination","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          limit +
          '","internalLinkPosition":"press room","internalLinkType":"press room:press releases:pagination","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
      } else {
        analyticsData =
          '{"eventAction":"press room:press releases:pagination","eventName":"press room:press releases:pagination","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          texts.all +
          '","internalLinkPosition":"press room","internalLinkType":"press room:press releases:pagination","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
      }
    } else if (page === "press-kits") {
      if (!all) {
        analyticsData =
          '{"eventAction":"press room:press kits:pagination","eventName":"press room:press kits:pagination","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          limit +
          '","internalLinkPosition":"press room","internalLinkType":"press room:press kits:pagination","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
      } else {
        analyticsData =
          '{"eventAction":"press room:press kits:pagination","eventName":"press room:press kits:pagination","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          texts.all +
          '","internalLinkPosition":"press room","internalLinkType":"press room:press kits:pagination","internalLinkZoneName":"plp:promo tiles","internalLinkURL":"n/a","clickInternalLinks":"true"}'
      }
    } else if (page === "blog") {
      const pageCount = !all ? limit : "all"
      const category = selectedTab ? sanitizeTextForAnalytics(selectedTab) : ""
      const linkName = `view ${pageCount}`
      const blogCategory =
        category && category === "how tos" ? "how to's" : category
      const linkUrl = ""
      const eventInfo = {
        clickInternalLinks: "true",
        eventAction: `blog listing:${blogCategory}:main content area:pagination`,
        eventName: `blog listing:${blogCategory}:main content area:pagination`,
        eventType: "navigation",
        internalLinkName: linkName ? linkName : "pagination",
        internalLinkPosition: "blogs listing",
        internalLinkType: `blog listing:navigation`,
        internalLinkZoneName: `blog listing:${blogCategory}:main content area`,
        internalLinkURL: linkUrl
          ? linkUrl.indexOf("http") === -1
            ? window.location.origin + linkUrl
            : linkUrl
          : "n/a",
      }
      analyticsData = JSON.stringify(eventInfo)
    } else {
      const internalLinkZoneName =
        pos === "top" ? `"plp:sticky nav filter"` : `"plp:promo tiles"`
      if (!all) {
        analyticsData =
          '{"eventAction":"plp pagination ' +
          pos +
          '","eventName":"plp pagination ' +
          pos +
          '","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          limit +
          '","internalLinkPosition":"plp","internalLinkType":"plp:pagination","internalLinkZoneName":' +
          internalLinkZoneName +
          ',"internalLinkURL":"n/a","clickInternalLinks":"true"}'
      } else {
        analyticsData =
          '{"eventAction":"plp pagination ' +
          pos +
          '","eventName":"plp pagination ' +
          pos +
          '","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"' +
          texts.all +
          '","internalLinkPosition":"plp","internalLinkType":"plp:pagination","internalLinkZoneName":' +
          internalLinkZoneName +
          ',"internalLinkURL":"n/a","clickInternalLinks":"true"}'
      }
    }

    return analyticsData
  }
  const checkPaginationPosition = limit => {
    onChange(limit)
  }
  return (
    <div className={styles.productPerPageWrapper}>
      <div className="products-per-page">
        {totalResults > 30 && (
          <ul role="none">
            <li className="disabled" aria-hidden>
              {texts.view}
            </li>
            {data.map((limit, i) => (
              <li
                aria-disabled={curRows === limit}
                role="button"
                tabIndex="0"
                aria-label={`${texts.view} ${limit} ${texts.items}`}
                className={
                  (curRows === limit && "disabled") + " gbh-data-layer"
                }
                data-gbh-data-layer={getAnalyticsData(limit)}
                key={i}
                onClick={() => checkPaginationPosition(limit)}
              >
                {limit}
              </li>
            ))}
            <li
              role="button"
              tabIndex="0"
              aria-label={`${texts.view} ${texts.all} ${texts.items}`}
              className={
                (curRows === totalResults && "disabled") + " gbh-data-layer"
              }
              data-gbh-data-layer={getAnalyticsData("", true)}
              onClick={() => onChange(totalResults)}
            >
              {texts.all}
            </li>
          </ul>
        )}
      </div>
    </div>
  )
}

export default ProductsPerPage
