import { useTranslation } from "next-i18next"

const useSocialSharei18n = (shareCartNum, brandName) => {
  const { t } = useTranslation()
  const i18n = {
    email: t("kf.share.label.email"),
    emailSubject: t("kf.share.label.emailSubject"),
    copyLink: t("kf.share.label.copyLink"),
    copyLinkIndication: t("kf.share.label.copyLinkIndication"),
    yourSharedCartNumber: t("kf.share.label.yourSharedCartNumber", {
      cartNumber: shareCartNum,
    }),
    linksCTA: siteKey =>
      t("kf.pdp.label.aria.socialLinksCTA", {
        brandName: brandName,
        siteKey: siteKey,
      }),
    openNewTabText: t("kf.share.label.openNewTabText"),
    shareCartDesc: t("kf.cart.label.shareCartDesc"),
    shareCart: t("kf.cart.label.shareCart"),
    mailSubject: t("kf.share.label.emailSubject"),
    downloadSpecs: t("kf.cart.label.downloadSpecs"),
  }

  return i18n
}

export default useSocialSharei18n
