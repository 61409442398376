import { getShortenedUrlwithGeneral, sanitizeUrl } from "@/utils/helper"

// get component social share link url
export const getLink = data => {
  const { href, url = "", assetUrl = "", compUrl = "", general } = data
  const isFullUrl = /^https?:\/\//i
  if (url && isFullUrl.test(url)) {
    return url
  }
  if (url) {
    return `${origin}${url}`
  } else if (assetUrl && compUrl) {
    // teaser video component link all cases
    if (!isFullUrl.test(compUrl)) {
      const newcompUrl = getShortenedUrlwithGeneral(compUrl, general)
      return `${origin}${newcompUrl}`
    } else {
      return compUrl
    }
  } else if (assetUrl) {
    return assetUrl
  } else {
    return sanitizeUrl(href ?? window.location.href)
  }
}
