export const addDataLayer = data => {
  const { analyticData, siteKey, link } = data
  const {
    eventAction = "n/a",
    eventName = "n/a",
    eventType = "informative",
    eventMsg = "n/a",
    eventStatus = "n/a",
    internalLinkPosition = "n/a",
    internalLinkType = "n/a",
    internalLinkZoneName = "n/a",
    internalLinkURL,
    custom,
  } = analyticData

  const eventInfo = {
    eventAction,
    eventName,
    eventType,
    eventMsg,
    eventStatus,
    internalLinkName: siteKey,
    internalLinkPosition,
    internalLinkType,
    internalLinkZoneName,
    internalLinkURL: link || internalLinkURL || "n/a",
    clickInternalLinks:
      siteKey === "copy link" || siteKey === "email a friend"
        ? "true"
        : "false",
  }

  if (window.dataLayerEventType) {
    eventInfo.internalLinkType = window.dataLayerEventType
  }

  if (window.dataLayerEventZoneName) {
    eventInfo.internalLinkZoneName = window.dataLayerEventZoneName
    if (eventAction === "n/a") {
      eventInfo.eventAction = window.dataLayerEventZoneName + ":share"
      eventInfo.eventName = window.dataLayerEventZoneName + ":share"
    }
  }

  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  let productInfo
  if (custom) {
    try {
      productInfo = JSON.parse(custom).productInfo
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
    productInfo,
  })
}
